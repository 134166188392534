import { createAsyncThunk } from '@reduxjs/toolkit';
import { tableOverTimeData, tableVideoData } from "../../api"
import { getVideoUrl } from "../../api/firebase"
import _ from "lodash"
import {getVideoOverTimeData} from "../../api/solo";
import { addVideo, removeVideo } from '../videos';
import { isSdk } from '../../api';
const overtimeMockData = [
    {
        energy: 100,
        engagement: 97,
        index: 0,
        interest: 100,
        mood: 100,
        stress: 0,
        wellbeing: 94,
    },
    {
        energy: 100,
        engagement: 99,
        index: 0,
        interest: 100,
        mood: 100,
        stress: 0,
        wellbeing: 94,
    },
    {
        energy: 99,
        engagement: 83,
        index: 2,
        interest: 53,
        mood: 54,
        stress: 23,
        wellbeing: 8,
    },
    {
        energy: 68,
        engagement: 100,
        index: 3,
        interest: 0,
        mood: 0,
        stress: 84,
        wellbeing: 0,
    }]

export const getTableOverTimeData = createAsyncThunk('table/getOvertime', async (data) => {
    const { sessionId, contentId, rowId } = data
    const overtime = await tableOverTimeData(sessionId, contentId)

    if(isSdk()){
        return {
            overTime: overtime
        }
    }

    for (let i in overtime) {
        overtime[i].index = Math.round(overtime[i].index)
    }

    return overtime //overtimeMockData
})

export const getTableVideoData = createAsyncThunk('table/getVideo', async (data) => {
    const { videoData, rowId, houseId } = data

    const overtime = await getVideoOverTimeData(videoData.videoId)

  //  const overtime = getOvertimeData(videoData.tracker)
    console.log("overtime", overtime)

    let path = videoData.videoPath
    let videoUrl;
    if(path){
        try {
            videoUrl = await getVideoUrl(path, houseId)
            console.log("videoUrl", videoUrl)
        }catch (e) {
            console.error("error getting video url", videoUrl)
        }
    }
    return { videoUrl, overTime: overtime }
})

const getOvertimeData = (tracker) => {
    const overtime = tracker.map(t => {
        let index = format(t.currentTime)

        let res = {
            index,
            mood: 0,
            energy: 0,
            wellbeing: 0,
            engagement: 0,
            interest: 0,
            stress: 0,
            neutral: 0,
            happy: 0,
            sad: 0,
            angry: 0,
            fearful: 0,
            disgusted: 0,
            surprised: 0
        }

        let data = t.result

        if(!data || data.length === 0) {
            return null
        }

        const moodArr = data.map(d => d.valence)
        const energyArr = data.map(d => d.energy)
        const wellbeingArr = data.map(d => d.wellbeing)
        const engagementArr = data.map(d => d.engagement)
        const interestArr = data.map(d => d.interest)
        const stressArr = data.map(d => d.stress)


        const happinessArr = data.map(d => _.get(d, "expressions.happy", _.get(d, "happy", 0)))
        const sadnessArr = data.map(d => _.get(d, "expressions.sad", _.get(d, "sad", 0)))
        const angerArr = data.map(d => _.get(d, "expressions.angry", _.get(d, "angry", 0)))
        const fearArr = data.map(d => _.get(d, "expressions.fearful", _.get(d, "fearful", 0)))
        const disgustArr = data.map(d => _.get(d, "expressions.disgusted", _.get(d, "disgusted", 0)))
        const surpriseArr = data.map(d => _.get(d, "expressions.surprised", _.get(d, "surprised", 0)))
        const calmArr = data.map(d => _.get(d, "expressions.neutral", _.get(d, "neutral", 0)))

        res.happy = normalizedAverage(happinessArr)
        res.sad = normalizedAverage(sadnessArr)
        res.angry = normalizedAverage(angerArr)
        res.fearful = normalizedAverage(fearArr)
        res.disgusted = normalizedAverage(disgustArr)
        res.surprised = normalizedAverage(surpriseArr)
        res.neutral = normalizedAverage(calmArr)

        res.mood = normalizedAverage(moodArr)
        res.energy = normalizedAverage(energyArr)
        res.wellbeing = normalizedAverage(wellbeingArr)
        res.engagement = normalizedAverage(engagementArr)
        res.interest = normalizedAverage(interestArr)
        res.stress = normalizedAverage(stressArr)

        return res
    }).filter(n => n)

    return overtime
}

const format = (s) => {
    let m = Math.floor(s / 60);
    m = (m >= 10) ? m : "" + m;
    s = Math.floor(s % 60);
    s = (s >= 10) ? s : "0" + s;
    return m + ":" + s;
}

const normalizedAverage = arr => normalizeValue(arr.reduce((a, b) => a + b, 0) / arr.length)

const normalizeValue = (value) => {
    let res
    if (value < 0) {
        res = 0
    } else if (value > 1) {
        res = 1
    } else {
        res = value
    }
    return Math.round(res * 100)
}

export const processVideo = (videoData) => async (dispatch) => {
    try {
        // ... existing video processing code ...
        
        // After successful upload, add to video map
        dispatch(addVideo({
            name: videoData.name,
            videoId: videoData.videoId
        }));
        
    } catch (error) {
        console.error('Error processing video:', error);
    }
};

export const deleteVideo = (videoData) => async (dispatch) => {
    try {
        // ... existing delete code ...
        
        // After successful deletion, remove from video map
        dispatch(removeVideo({
            name: videoData.name
        }));
        
    } catch (error) {
        console.error('Error deleting video:', error);
    }
};
